import React from "react"
import AddCalendar from "@templates/AddCalendar"
import SEO from "@components/seo"

const AddCal = () => {
  return (
    <>
      <SEO title="Adding calendar..." />
      <AddCalendar />
    </>
  )
}

export default AddCal
