import React, { useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"
import { toast } from "react-toastify"
import { Link, navigate } from "gatsby"
import ProgressDots from "../../components/ProgressDots"
import get from "lodash/get"

const AddCalendarTemplate = () => {
  const { makeRequest } = useAuth() || {}

  const [validationError, setVe] = useState(false)

  useEffect(() => {
    const validateCalendar = async (params) => {
      try {
        const res = await makeRequest("validate-calendar", {
          params,
        })
          .then((res) => {
            if (!res || !res.validated) {
              setVe(true)
              return
            }
            const redirect_url = params.state
              ? params.state.split("_")[1]
              : null
            if (redirect_url) {
              window.location.href = redirect_url + "/calendar-availability"
            } else {
              navigate("calendar-availability")
            }
          })
          .catch((e) => {
            console.log(e)
          })
      } catch (error) {
        console.log(error)
        toast.error(
          "Failed to link the calendar to your account. Please retry."
        )
      }
    }
    if (typeof window !== "undefined") {
      // const params = qs.parse(window.location.search)
      const paramsString = get(window, "location.search") || ""
      let searchParams = new URLSearchParams(paramsString)
      /**
       * fetch data from db for validation
       */
      validateCalendar(paramsToObject(searchParams))
        .then(() => null)
        .catch((e) => null)
    }
  }, [])

  if (validationError) {
    return (
      <div className={"container text-center"}>
        <p className={"mt-5"}>
          Error Occurred. Please delete your calendar and try again.
          <br />
          If this persists, please contact administrator
        </p>
        <Link to={"/calendar-availability"} className={"btn btn-primary"}>
          View Calendars
        </Link>
      </div>
    )
  }

  return (
    <div>
      <ProgressDots active />
    </div>
  )
}

const paramsToObject = (entries) => {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

export default AddCalendarTemplate
